import React from "react"
import Layout from "../components/layout"
import EventListing from "../components/EventListing"

export default ({ pageContext: { events }, location }) => {
  const featuredEvent = events[0]

  return (
    <Layout location={location}>
      <div className="container">
        <div className="pastEventsSection">
          <h2 className="sectionTitle">Upcomming Events</h2>
          <EventListing featuredEvent={events[0]} />
        </div>
        <div className="pastEventsSection">
          <h2 className="sectionTitle">Past Events</h2>
          <EventListing featuredEvent={events[1]} />
        </div>
      </div>
    </Layout>
  )
}
