import React from "react"
import { Link } from "gatsby"

export default ({ featuredEvent }) => {
  const [
    featuredEventDay,
    featuredEventMonth,
    featuredEventYear,
  ] = featuredEvent.acf.date.split("/")

  const featuredEventDate = new Date(
    featuredEventYear,
    featuredEventMonth - 1,
    featuredEventDay
  ).toLocaleString("en-us", { month: "long", day: "2-digit", year: "numeric" })

  const ticketLink = featuredEvent.acf.ticket_url ? (
    <a
      className="button"
      style={{ marginBottom: "1rem" }}
      href={featuredEvent.acf.ticket_url}
      target="_blank"
      rel="noopener noreferrer"
    >
      Buy Tickets
    </a>
  ) : null
  return (
    <div className="featuredEvent">
      <div className="featuredEvent__info">
        <div className="featuredEvent__date">{featuredEventDate}</div>
        <div
          className="featuredEvent__title"
          dangerouslySetInnerHTML={{ __html: featuredEvent.title.rendered }}
        ></div>
        <div className="featuredEvent__time">
          {featuredEvent.acf.start_time}
        </div>
        <div
          className="featuredEvent__description"
          dangerouslySetInnerHTML={{
            __html: featuredEvent.acf.description.substr(0, 210) + "...",
          }}
        ></div>
        <Link
          to={`/events/${featuredEvent.slug}`}
          className="button-green mr-2"
        >
          Event Info
        </Link>
        {ticketLink}
      </div>
      <div className="featuredEvent__image">
        <Link to={`/events/${featuredEvent.slug}`}>
          <img src={featuredEvent.acf.image} alt="" />
        </Link>
      </div>
    </div>
  )
}
